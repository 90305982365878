<template>
  <div class="wrapper">
    <div class="flex flex-col w-full">
      <div class="flex flex-col items-center justify-center">
        <div v-if="loaded" class="flex flex-col">
          <span>{{ token }}</span>
        </div>
        <div
          v-if="success"
          class="flex flex-col w-6/12 mx-auto items-center my-auto"
        >
          <span class="font-semibold text-3xl text-green mb-8"
            >Täname väga ostu eest!</span
          >
          <span class="text-lg mb-8"
            >Kui oled Gewodo tööriistadega rahul, siis jaga infot ka oma
            tuttavatega. Nii jõuame oma lihtsa ja soodsa teenusega kõigi
            vajajateni ning saame jõudu uute tööriistade välja
            töötamiseks!</span
          >
          <span class="text-lg font-medium">Meeldivat koostööd soovides,</span>
          <span class="text-lg font-medium mb-8">Sinu Gewodo tiim</span>
          <img src="/logotext.png" class="mb-8 h-32" />
        </div>
        <div v-if="canceled" class="flex flex-col items-center">
          <h2 class="mb-8 text-3xl">Tellimine tühistatud</h2>
          <span class="mb-8 text-lg">
            {{ responseText }}
          </span>
        </div>
        <button
          @click="$router.push('/subscriptions')"
          class="new-button-green label"
        >
          Tagasi ettevõtte lehele
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  name: "IndividualSubscriptionRedirect",
  mixins: [RequestHandler],
  data() {
    return {
      token: null,
      loaded: false,
      success: false,
      canceled: false,
      error: false,
      responseText: "",
      responseTextActive: false
    };
  },
  methods: {
    sendPaymentToBackend() {
      this.apiRequest(
        "subscription/individual/return/?payment_token=" +
          this.$route.query.payment_token,
        "post"
      ).then(res => {
        if (res.status === 200) {
          this.responseText = this.$route.query.payment_token;
          this.success = true;
        } else if (res.status === 202) {
          this.canceled = true;
          this.responseText = "Makse tühistatud. Pakett ei rakendu kontole.";
        } else {
          this.error = true;
        }
        this.responseTextActive = true;
        this.loaded = true;
      });
    }
  },
  mounted() {
    this.sendPaymentToBackend();
  }
};
</script>
